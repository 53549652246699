
import { getCompanyStickyFiguresQuery, getCompanyPropertiesCountQuery } from "~/helpers/apollo/apollo-company-query-helpers.js";
import CompanyCard from "~/components/EntityCard/CompanyCard.vue";

export default {
  components: { CompanyCard },

  apollo: {
    companyStickyFigures: getCompanyStickyFiguresQuery,
    companyProperties: getCompanyPropertiesCountQuery,
  },

  props: {
    cvrNumber: {
      type: Number,
      required: true,
    },
  },

  computed: {
    loading: function () {
      return this.$apollo.queries.companyStickyFigures.loading;
    },
  },
};
