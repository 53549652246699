
export default {
  inheritAttrs: false,

  computed: {
    activeTab: function () {
      return this.$route.name;
    },
  },

  methods: {
    navigateTo(route) {
      if (this.activeTab.includes(route)) {
        return;
      }
      this.logAmplitudeEvent(route);
      this.$router.replace(this.getRoute(route));
    },

    logAmplitudeEvent(route) {
      this.$amplitude.track({ event_type: `Navigate to company-${route}` });
    },

    getRoute(path) {
      let name = "explore-company-id-" + path;

      return { name: name, params: { id: this.$route.params.id } };
    },
  },
};
