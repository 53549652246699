
import Intro from "./components/Intro.vue";
import Tabs from "./components/Tabs.vue";
import { getCompanyPropertiesQuery } from "~/helpers/apollo/apollo-company-query-helpers.js";
import NoFremiumAccess from "~/components/NoFremiumAccess.vue";

export default {
  name: "CompanyNuxtParent",

  components: {
    Intro,
    Tabs,
    NoFremiumAccess,
  },

  apollo: {
    companyProperties: getCompanyPropertiesQuery,
  },

  computed: {
    cvrNumber: function () {
      return parseInt(this.$route.params.id, 10);
    },
  },
};
